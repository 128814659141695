.header::-webkit-scrollbar{
  height: 3px;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddcba3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.header{
  overflow-x: scroll;
 
}
.nav-link{
  color:white!important;
  font-family: 'Fredericka the Great';
  font-size: 24px;
  margin-inline: 10px;
  &:hover{
    color: #caaf91!important;
    padding-bottom: 5px;
    border-bottom: 2px solid white;
  }
}
.connect{
  color:white !important;
  font-family: 'Fredericka the Great';
  font-size: 24px;
  margin-inline: 15px;

  &:hover {
    color: #caaf91 !important;
    padding-bottom: 5px;
    border-bottom: 2px solid white;
  }
}