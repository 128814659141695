.mintCard {
  width: 90%;
  height: 440px;
  position: relative;
  border-radius: 15px;
  margin: auto;
  background: url("https://res.cloudinary.com/dhmglymaz/image/upload/c_scale,q_40,w_800/v1654095651/Dinger/qhhpl1ewu0qh0jreohix.png") no-repeat;
  background-size: 107% 115%;
  background-position:50% 6%;

  @media screen and (max-width: 1200px) {
    height: 90%;
    flex-direction: column;
    background: url("https://res.cloudinary.com/dhmglymaz/image/upload/c_scale,q_40,w_800/v1654095651/Dinger/qhhpl1ewu0qh0jreohix.png") no-repeat;
    background-size: 110% 110%;
    background-position: 30% 12%;
  }

  .mint-left {
    width: 80%;
    border-radius: 10px 0 0 10px  ;

    @media screen and (max-width: 1200px) {
      width: 100%;
      flex-direction: column;
      margin: 0;
      padding: 0;
      align-items: center;
      border-bottom: 4px dotted #a62f24;
      border-radius: 15px 15px 0 0px;
      background: none;
    }
  }

  .title {
    font-family: 'Fredericka the Great';
    font-size: 50px;
    text-transform: uppercase;
    -webkit-text-fill-color: black;
    fill-rule: evenodd;

    @media (min-width: 768px) and (max-width:1500px){
      font-size: 38px;
      bottom: 110px;
    }
    @media screen and (max-width: 768px) {
      font-size: 28px;
      bottom: 110px;
    }
  }




  .mint-right {
    width: 28%;
    border-radius: 0 10px 10px 0;
    border-left: 4px dotted #a62f24;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1200px) {
      width: 100%;
      margin: 0;
      border-radius: 0px 0px 15px 15px;
      border-left:none ;
    }

    
    .barcode {
      width: 95%;
      height: 50%;
      border-bottom: 4px dotted black;
      padding-bottom: 15px;
    }

    .btn {
      width: 100%;
      padding: 0;
      background-color: #a62f24;
      border: none;
      font-family: 'Fredericka the Great';
      font-size: 24px;
      text-transform: uppercase;
    }
  }
}