#WEB3_CONNECT_MODAL_ID {
  .web3modal-modal-lightbox {
    z-index: 10000;
    .web3modal-modal-card{
      display: flex;
      width: 480px;
      height: 250px;
      background: url("../../../assets/wallet-bg.png") no-repeat;
      background-size: 100% 250px;
      background-position:50% 50%;
      justify-content: center;
      // &::before{
      //   content: "";
      //   position: absolute;
      //   width: 200%;
      //   height: 200%;
      //   top: -50%;
      //   left: -50%;
      //   z-index: -1;
        
      //    @media screen and (max-width:"768px") {
      //   flex-direction: column;
      //   transform: rotate(90deg);;
      // }
      // }
     
      .web3modal-provider-wrapper{
        width: 130px;
        border: none;
        .web3modal-provider-container{
          background: none;
        }
      }
    }
    
    .web3modal-provider-name{
      font-size: 16px;
    }
    .web3modal-provider-description {
      display: none;
    }
  }
  
}