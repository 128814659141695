.about{
  height: 100%;
  text-align: justify;
  position: relative;
  padding: 75px 0;
  @media screen and (max-width:768px) {
    height: 100%;
    padding-bottom: 55px;
    text-align: start;

  }
  p {
    font-size: 24px;
    font-weight: 500;
    padding-inline: 25px;
    line-height: 40px;
    @media screen and (max-width:768px) {
      font-size: 18px;
      margin-top: 15px;
      line-height: 27px;
    }
  }
  a{
    color: #a62f24;
  }
  .squad{
    width: 40%;
    height: 40%;
    margin-inline: 25px;
    float: left;
    @media screen and (max-width:768px) {
      width: 90%;   
      margin-bottom: 15px;   
    }
  }
.right{
  padding-inline: 45px;

  
}
}
