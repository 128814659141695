.champion{
  height: 100%;
  position: relative;
  padding: 95px 0;
  margin-top: 35px;
   @media screen and (max-width:768px) {
     height: 100%;
     padding-bottom: 55px;
   }
  .left{
    padding-inline: 40px;
   
    
  p{
    font-weight: 500;
    font-size: 24px;
    text-align: start;
     @media screen and (max-width:768px) {
       font-size: 18px;
       line-height: 27px;
     }
  }
}
.squad{
    width: 100%;
    margin-inline: 25px;
    @media screen and (max-width:768px) {
      width: 90%;
      margin: auto;
    }
  }
.row{
  .card{
    width: 85%;
    height: 100%;
  }
}
}