.thesquad{
  height: 100%;
  position: relative;
  padding: 75px 0;
  margin-top: 35px;
  @media screen and (max-width:768px) {
    height: 100%;
    padding-bottom: 55px;
  }
  .left{
    padding-inline: 50px;  
    @media screen and (max-width:768px) {
      padding: 0;
      padding-left: 25px;
      padding-right: 25px;
    }
   
  p{
    font-family: 'Book';
    font-weight: 500;
    font-size: 24px;
    text-align: start;
    line-height: 40px;
    @media screen and (max-width:768px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
}
.squad{
    width: 95%;
    margin-inline: 35px;
    @media screen and (max-width:768px) {
      width: 90%;
      margin: auto;
    }
  }
.row{
  .card{
    width: 85%;
    height: 100%;
  }
}
}