.modal-dialog {
  width: 80% !important;
}

.modal-content {
  background-color: transparent !important;
  border: none!important;

}



.videopop {
  position: relative;
  padding: 130px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 90vh;

  @media screen and (max-width: 1300px) {
    padding: 8px;
    margin-top: 80px;
    width: 90vw;
    height: 80vh;

  }
   .cross {
    font-size: 25px;
    font-weight: 800;
    position: absolute;
    top: 75px;
    right: 20px;
    cursor: pointer;
    p{
      color: #a62f24;
    }

    @media screen and (max-width: 1300px) {
      top:145px;
      right: 15px;
    }
  }
  .close{
    font-size: 18px;
    font-weight: 500;
    position: relative;
    bottom: 10px;
  }
}
.whitelist {
  position: relative;
  padding: 200px 80px;
  text-align: center;
  background: url("https://res.cloudinary.com/dhmglymaz/image/upload/c_scale,q_40,w_680/v1654116155/Dinger/uyabmfpa2krh2riszgar.png") no-repeat center/105%;
  display: flex;
  flex-direction: column;
  height: 90vh;

  @media screen and (max-width: 768px) {
    padding: 8px;
    margin-top: 80px;
    width: 90vw;
    height: 80vh;
    background: url("https://res.cloudinary.com/dhmglymaz/image/upload/c_scale,q_53,w_680/v1654116155/Dinger/uyabmfpa2krh2riszgar.png") no-repeat center/100%;

  }

  .cross {
    font-size: 25px;
    font-weight: 800;
    position: absolute;
    top: 75px;
    right: 20px;
    cursor: pointer;
    p{
      color: #a62f24;
    }

    @media screen and (max-width: 768px) {
      top:145px;
      right: 15px;
    }
  }
  .close{
    font-size: 18px;
    font-weight: 500;
    position: relative;
    bottom: 10px;
  }

.title {
    font-family: 'Fredericka the Great';
    font-size: 32px;
    text-transform: uppercase;
    position: absolute;
    top: 10px;
    left: 25%;
    -webkit-text-fill-color: black;
    fill-rule: evenodd;
    margin-top: 115px;
h1{
  font-size: 50px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
   
  }
}
p{
  font-family: 'Fredericka the Great';
}
    @media screen and (max-width: 768px) {
      font-size: 18px;
      top: 65px;
      left: 25%;
    }
  }

  .steps{
   font-family: 'Fredericka the Great';
   font-size: 16px;
   font-weight: 600;
   text-transform: uppercase;
   -webkit-text-fill-color: black;
   margin-top: 95px;
   @media screen and (max-width: 768px) {
     font-size: 12px;
     margin-top: 265px;
   }
  }
  .content {
    position: absolute;
    padding: 0;
    left: 15%;
    bottom: 40px;
    @media screen and (max-width: 768px) {
      bottom: 100px;
      left: 0;
      padding: 12px;
      font-size: 16px;
      img {
        width: 40px;
      }
      .fb-logo{
        width: 30px;
      }
      
    }
  }
}