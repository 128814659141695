.App {
  text-align: center;
  background-image: url("https://res.cloudinary.com/dhmglymaz/image/upload/c_scale,w_945/v1654094605/Dinger/jkgiimffjqzhvsjxfiz1.jpg");
  background-repeat: repeat;
  background-size: 1000px;
  z-index: 0;
  overflow: hidden;
}
.cursor-pointer{
  cursor: pointer;
}
@font-face {
  font-family: 'Britannic Bold';
  src: url("../src/assets/fonts/BritannicBold.ttf") format('truetype');
}


@font-face {
  font-family: 'Book';
  src: url("../src/assets/fonts/BookAntiqua.ttf") format('truetype');
}

p{
  font-family: 'Book';
}
