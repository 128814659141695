.reveal{
  background-image: url("https://res.cloudinary.com/dhmglymaz/image/upload/v1654094610/Dinger/b3xwr0vchw8rtxpxt3nf.png");
  background-repeat: no-repeat;
  background-size: 300px 0px;
  height: 100%;
  position: relative;
  padding-top: 95px;
  @media screen and (max-width: 768px){
      height: 100%;
      padding-top: 55px;
      padding-bottom: 0;
    }
}
.badge-dinger{
    position: relative;
    bottom: 180px; 
    width: 55%; 
    object-fit: cover;
    margin-left: 105px;
    z-index: 100;
    @media screen and (max-width: 768px){
      width: 120%;
      bottom: 98px;
      margin: 0;
    }
}
  .badge-text{
      font-family: 'Fredericka the Great';
      font-size: 40px;
      text-transform: uppercase;
      position: relative;
      bottom: 215px;
      -webkit-text-fill-color: #b92135;
      fill-rule: evenodd;
      @media screen and (max-width: 768px){
      font-size: 28px;
      bottom: 110px;
    }
    }