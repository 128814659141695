.teamcardlist{
  flex-wrap: wrap;
  justify-content:space-around ;
    .cont {
      position: relative;
    }

    .image {
      opacity: 1;
      display: block;
      height: auto;
      transition: .5s ease;
      backface-visibility: hidden;
    }

    .middle {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }

    
    .cont:hover .image {
      // transform: scale(1.06);
    }

    .cont:hover .middle {
      opacity: 1;
    }

    .text {
      color: white;
      font-size: 16px;
    }
}