.revealcardlist{
  overflow-x: scroll ;
  scroll-behavior: smooth;
  &::-webkit-scrollbar{
    display: none;
  }
   -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
    a{
      color: none;
      text-decoration: none;
    }
}