.roadmap{
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.6) 0),
    url("https://drive.google.com/thumbnail?id=1OoYu9R_oCxkLwpyzleABsp94vDxdADHI&sz=w1920-h1080");
  background-repeat: no-repeat;
  background-size: cover;
  height: 145vh;
  z-index: 10;
  position: relative;
  margin-top: 35px;
  @media screen and (max-width:"768px") {
    height: 100%;
  }
  .bgDesk{
    z-index: 10;
    height: 70%;
  }
  .red{
    filter: brightness(50%) sepia(100) saturate(100) hue-rotate(20deg);
  }
}