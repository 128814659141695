.banner{
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.2) 0),
  url("https://res.cloudinary.com/dhmglymaz/image/upload/c_scale,w_1200/v1654117784/Dinger/agfvsvgj1xsl5qgdimtb.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 110vh;
  @media screen and (max-width:"768px") {
    height: 100vh;
    background-position: -650px top;
  }
}