.minting{
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.8) 0),
  url("https://res.cloudinary.com/dhmglymaz/image/upload/v1654094610/Dinger/b3xwr0vchw8rtxpxt3nf.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  z-index: 10;
  position: relative;
  padding: 105px 0;
  @media screen and (max-width:"768px") {
    height: 100%;
  }
  .ticket{
    position: relative;
    width: 60%;
    bottom: 130px;
     @media screen and (max-width:"768px") {
    width: 90%;
    bottom: 60px;
  }
    &.end{
      left: 20%;
      z-index: 10;
 @media screen and (max-width:"768px") {
    left: 5%;
  }
    }
    
  }
}