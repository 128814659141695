.faq{
margin-top: 130px;
height: 40%;
z-index: 20;
height: 100%;
position: relative;
 @media screen and (max-width: 768px) {
   height: 100%!important;
 }
.faq_container{
  background: #ddcba3;
  padding: 5px 10px;
  a{
    color: #a62f24;
  }
}
}